@import './variables.scss';


// tenant colours. 

.tenant-none {
  background: $invalid-tenant-colour;
} 

.tenant-nzap {
  background: $nzap-colour;
} 

.grower-login-page {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .blob {
    position: fixed;
    
    &.top {
      top: 5vh;
      width: 100vw;
      transform-origin: center center;
      transform: scale(2, -2);
      fill: lighten($burgandy, 5%);
    }

    &.bottom {
      bottom: 0;
      width: 200vw;
      fill: lighten($burgandy, 5%);
    }
  }

  .tree {
    position: fixed;
    bottom: 9vh;
    right: 1.5em;
    height: 100px;
    filter: brightness(255) invert(1);
    opacity: 0.15;
  }

  >.row>div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-container {
    width: 100%;
    border-radius: 12px;
    padding: 2em 1em;
    max-width:960px;
    z-index: 2000;
    .form-control,
    label {
      width: 100%;
    }

    .form-control {
      background: #451408;
      border: 0;
      color: #fff;
      padding: 1em 1.5em;
      height: 3em;
    }

    // Login buttons for each grower tenant.
    .login-btn-nzap {
      background: #0275d8;
      margin-top: 0em;
      font-size: 18px;
      font-weight: 500;
      border: 0;
      padding: .75em 2em;
    }
  }
}