.trap {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 25px;

  .trap-title {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 25px;
      overflow: visible;
      height: 2.5em;
    }
  }

  .loader-inline {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px;
  }

  .trap-instruction {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0 0 0 5px;
    }
  }

  .label {
    text-align: left;
  }

  .switch {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .switch-toggle {
      justify-content: flex-end;
      text-align: right;
    }
  }

  .notes {
    text-align: left;

    .label {
      justify-content: flex-start;
    }

  }
}

.trap-messages {
  line-height: 1.25em;
  display: block;
}