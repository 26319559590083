@import url('https://fonts.googleapis.com/css2?family=Saira:wght@300;400;700&display=swap');
@import './variables.scss';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.fill {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  &.flex {
    display: flex;
    overflow-y: scroll;

    &.center {
      align-items: center;
      justify-content: center;
    }
&.top {
  align-items: flex-start;
  justify-content: center;
  padding-top: 75px;
}
  }
}

.primary-btn {
  margin-top: 1em;
  background: $burgandy;
  border: 0;
  padding: .75em 2em;
}

.run-number-btn {
  margin: 0 1em;
}

.Toastify__toast {
  padding: 1em;

  &.Toastify__toast--error {
    background: $error;
  }
}

.toast-btn {
  background: rgba(0, 0, 0, .2);
  transition: 0.2s all ease-in;
  border-color: transparent;
  padding: .75em;
  color: #fff;

}

.toast-btn:hover,
.toast-btn:active,
.toast-btn:focus {
  background: rgba(0, 0, 0, .25);
  border-color: transparent;
}
.list-group {
  margin-bottom: 15px;
}
.no-margin {
  margin: 0;
}
.no-padding {
  padding: 0;
}
.spacer {
  width: 100%;
  margin: 5px;
}
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.Toastify {
  z-index: 9999;
  .Toastify__toast {
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0 5px 15px -5px #666;

    &.Toastify__toast--error {
      background: #b74126;
    }

    &.Toastify_toast--success {
      background: #6cc73e;
    }
  }
}