.empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 35px;
  text-align: center;
  min-height: 70vh;

  * {
    color: #ccc;
  }
}