
.loading-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

img.logo {
  &.loading {
    max-height: 100px;
    min-height: 100px;
  }
}
