@import "./variables.scss";

.tenant-nzap {
  background-color: $nzap-colour !important;
  background: $nzap-colour !important;
}

.navbar {
  padding: 0;
  background-color: $burgandy;
  background: $burgandy;
  &.home {
    padding: 0 0 0 2rem;
  }
  .container {
    z-index: 2000;
    padding: 0;
  }

  z-index: 1050;
  .navbar-brand {
    font-family: "Saira", sans-serif;
    display: flex;
    align-items: center;

    .navbar-text {
      padding: 0;
    }
    .btn-back {
      outline: none;
      border: none;
      color: #fff;
    }
  }

  .nav-right {
    display: flex;
    align-items: center;
    color: #fff;

    i {
      color: #fff;
    }
  }
  &.offline {
    background: #ccc;
    .btn-back {
      outline: none;
      border: none;
      color: #444;
    }
    * {
      color: #444;
    }
  }
}

.connection-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .online {
    * {
      padding: 0;
      line-height: 1;
    }

    i {
      color: green !important;
      max-height: 10px;
    }
  }

  .offline {
    color: #ccc;
  }
}
.nav-menu {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  background: $burgandy;
  color: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 100px;
  z-index: 1040;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 225px;

  .row {
    min-width: 90%;
    margin: 0;
  }

  .run-number-form {
    display: flex;
    justify-content: space-between;
    button {
      margin: 0;
      margin-left: 15px;
    }
  }

  .sync-form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-left: 0;
    margin-right: 0;
    padding: 0 15px;
    max-width: 100%;
    div > p {
      text-align: center;
    }
    .queue-status {
      margin: 0;
    }
    button {
      margin-left: 15px;
    }
  }

  .form-control {
    background: #451408;
    border: 0;
    color: #fff;
    padding: 1em 1.5em;
    height: 3em;
  }

  button {
    background: #e02a00;
  }
  &.offline {
    background: #ccc;
    color: #222;

    .form-control {
      background: #fff;
      border: 0;
      color: #222;
      padding: 1em 1.5em;
      height: 3em;
    }

    button {
      background: #222;
      color: #fff;
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  .logout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    * {
      flex: 1;
    }
    button {
      margin: 0;
      margin-left: 15px;
    }
  }
  .version {
    background: transparent;
    text-decoration: none;
    color: white;
    padding: 0 1em 1em 1em;
    opacity: 0.75;
  }
}
