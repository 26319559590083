@import './variables.scss';

.run-number-page {
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  text-align: center;

  .row {
    //width: 100%;

    .col-12 {
      padding: 0;
    }
  }
}