.collapse-list-item {
  display: flex;
  align-items: center;
  .triangle {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  position: relative;
}
.list-group-item-heading {
  display: flex;
  align-items: center;
  .sync-pending {
    opacity: 0.2;
    font-size: 0.75em;
    padding-left: 5px;
    display: inline-block;
  }
}

.trap-list-item:nth-child(odd) {
  background: #f6f6f6;
}
  