.properties {
  padding: 15px;
}
.filter-container {
  position: relative;

  .filter-x {
    position: absolute;
    z-index: 1000;
    margin: 0;
    top: -3px;
    right: 5px;
    transition: 0.1s opacity ease-in;
    outline: none !important;
    box-shadow: none !important;

    &.show {
      opacity: 1;
      cursor: pointer;
      pointer-events: all;
    }

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.filter-text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
  padding: 10px 0;
  color: #aaa;
}

.show {
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
}

.hide {
  opacity: 0;
  pointer-events: none;
}

.page-header {
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  h3 {
    margin: 0 0 0 10px;
  }
}
