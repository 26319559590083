.app-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  margin-top: 85px;
  padding: 5px;

  .app-sidebar {
    position: relative;
    height: 100%;
    min-width: 172px;
    max-width: 172px;
  }

  .app-main {
    position: relative;
    width: calc(100vw - 172px);
    padding: 0;
  }
}
